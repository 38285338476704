import styled from '@emotion/styled'
import { SocialLinks } from '../../navigation/SocialLinks/SocialLinks'
import { colors } from '../../__styling/settings/colors'
import { fonts } from '../../__styling/settings/fonts'
import { breakpoint } from '../../__styling/style-functions'
import { calcRem } from '../../__styling/style-functions/calc-rem'

type Compact = { compact?: boolean }

const backgroundImage: string = require('./components/PNLN-social-media-banner.jpg')

export const StyledStmSocialContainer = styled('div')({
    backgroundImage: `url('${backgroundImage}')`,
    backgroundPosition: 'center left',
    backgroundSize: 'cover',
    display: 'flex',
    fontFamily: fonts.perthnow.heading,
    height: 70,
    overflow: 'hidden',
    position: 'relative',
})

export const StyledStmSocialFlag = styled('div')({
    background: colors.white,
    color: colors.white,
    height: '100%',
    marginLeft: 'auto',
    position: 'relative',

    display: 'flex',
    alignItems: 'center',

    '&::before': {
        background: colors.white,
        content: `''`,
        height: '100%',
        left: `-${47 / 2}px`,
        position: 'absolute',
        top: 0,
        transform: 'skew(-34deg, 0deg)',
        width: 47,
    },
})

export const StyledFlagContent = styled('div')({
    padding: calcRem(9, 19, 9, 0),
    position: 'relative',
    zIndex: 1,

    // magic number used with "triangle" pseudo element width to match design
    width: 152,

    [breakpoint('sm')]: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 150, // width to match design for small devices
        padding: calcRem(9, 29, 9, 15),
    },

    [breakpoint('md')]: {
        width: 140, // width to match design for medium - larger devices
        padding: calcRem(9, 23, 9, 3),
    },
})

export const StyledIconContainer = styled('div')({
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
})

export const StyledSocialLinks = styled(SocialLinks)<Compact>(
    {
        'a:last-of-type': {
            marginRight: 0,
        },
    },
    ({ compact }) => ({
        a: {
            marginTop: 0,
            width: calcRem(compact ? 24 : 30),
            height: calcRem(compact ? 24 : 30),
            marginRight: compact ? calcRem(6) : undefined,
        },
    }),
)

export const StyledTitle = styled('h3')({
    fontSize: calcRem(22),
    lineHeight: calcRem(22),
    margin: 0,
    marginBottom: calcRem(4),
    color: colors.white,

    [breakpoint('sm')]: {
        margin: 0,
        marginRight: calcRem(29),
    },
})

export const StyledLogo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: calcRem(11),
    paddingTop: calcRem(11),
    paddingBottom: calcRem(11),
})
